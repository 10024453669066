// eslint-disable-next-line no-unused-vars
export default defineNuxtRouteMiddleware(async (to, from) => {
  const { isMaintenance, yandexMetrikaId } = useRuntimeConfig().public;

  if (window?.ym)
    window.ym(yandexMetrikaId, 'hit', window.location.origin + to.path, {
      referer: window.location.origin + from.path,
    });

  if (to.path !== '/' && to.path.endsWith('/')) {
    const { path, query, hash } = to;
    const nextPath = path.replace(/\/+$/, '') || '/';
    const nextRoute = { path: nextPath, query, hash };
    return navigateTo(nextRoute, { redirectCode: 301 });
  }

  const allowedRoutes = ['/maintenance'];

  if (isMaintenance === 'true' && !allowedRoutes.includes(to.path)) {
    return navigateTo('/maintenance');
  } else if (isMaintenance === 'false' && to.path === '/maintenance') {
    return navigateTo('/');
  }
});
